<template>
  <div class="common-container cad-container">
    <div class="flex-center p24 home-top-wrapper">
      <div class="flex top-wrapper">
        <div class="flex-center logo" @click.stop="$router.replace('/staging')">
          <img src="~images/home/logo.png" alt="" />
          <div class="cad-title">{{ productName }}</div>
        </div>
      </div>
      <div class="flex top-right-wrapper">
        <el-button @click="fullmap">居中</el-button>
      </div>
    </div>

    <div
      class="mt30 columm-center cad-wraapper"
      style="height: calc(100% - 64px);"
    >
      <div class="cad-main">
        <div
          class="map"
          id="map"
          ref="page_map"
          :style="{
            left: '0px',
            right: '0px',
            bottom: '0px'
          }"
          v-if="isLoading"
        >
          <div ref="page_canvas" class="canvas" tabindex="0">
            <!-- 右击事件 -->
          </div>
        </div>
      </div>
    </div>
    <div class="mask-wrapper" v-if="showSpray"></div>
    <!--  -->
    <div class="mask-fixed-box" v-show="mapLoading">
      <div class="mask-fixed">
        <el-progress
          type="dashboard"
          :percentage="percentage"
          :color="percentageColors"
        ></el-progress>
        <div class="mask-fixed-title">单桌派平台,{{ percentageText }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { get, post } from "@/api/index";
import { toolMixin } from "@/utils/toolMixin";
import { CadMap } from "@/cadplugins/map/cadmap";
import { CommandFactory } from "@/cadplugins/map/commandfactory";
import { SprinklerReactor } from "@/business/sprinkler/sprinklerreactor";
import { SprinkerRegisterCommand } from "@/business/sprinkler/sprinkerregistercommand";
import { CustomCommandManager } from "@/business/sprinkler/customcommandmanager";
import opentype from "opentype.js";
import iconv from "iconv-lite";
import { getToken } from "@/utils/auth";

export default {
  name: "share",
  mixins: [toolMixin],
  components: {},
  computed: {},
  data() {
    return {
      mapLoading: false,
      mapId: "", //"b5910243","3b2adee2"
      isLoading: false,
      md5: "",
      productId: "",
      shareUrl: "",
      cadUrl: "/dwg/convert/public/",
      mapTimer: null,
      canvasWidth: 0,
      canvaslength: 0,
      productName: "",
      sup_this: this,
      percentageText: "加载中...",
      percentage: 10,
      percentageColors: [
        { color: "#f56c6c", percentage: 20 },
        { color: "#e6a23c", percentage: 40 },
        { color: "#5cb87a", percentage: 60 },
        { color: "#1989fa", percentage: 80 },
        { color: "#6f7ad3", percentage: 100 }
      ]
    };
  },
  created() {
    const resourceList = ["fonts/正等线体.fnt"];
    window.PIXI.Assets.load(resourceList).then(res => {
      console.log("res", res);
    });
    SprinkerRegisterCommand.init();

    window.opentype = opentype;
    window.iconv = iconv;
  },
  activated() {
    this.isOnShowEdit = getToken();
    this.percentageText = "加载中...";
    this.percentage = 0;
    //注意是等待字体文件加载完成之后再加载地图;
    this.initCadMap();
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onEsc);
  },
  methods: {
    changeMapSize() {
      const page_map = this.$refs.page_map;
      this.canvasWidth = page_map.clientWidth;
      let canvasWidth = document.body.clientWidth;

      this.canvasWidth = canvasWidth;
      this.canvaslength = page_map.clientHeight;
      this.pixiMap.resize(this.canvasWidth, this.canvaslength);
    },
    loadFont() {
      return new Promise((resolve, reject) => {
        if (window.font) {
          resolve();
        } else {
          window.opentype.load("汉仪中等线.ttf", function(err, font) {
            if (err) {
              alert("加载字体失败");
              reject(err);
            } else {
              window.font = font;
              resolve();
            }
          });
        }
      });
    },
    initCadMap() {
      this.loadFont()
        .then(res => {
          this.$nextTick(() => {
            this.md5 = this.$route.query.md5 || "";
            this.productName = this.$route.query.productName || "";
            this.productId = this.$route.query.productId || "";
            if (this.md5 && this.productId) {
              this.resetData();
              this.mapTimer = null;
              this.isLoading = true;
              this.mapLoading = true;
              this.mapId = "";
              document.addEventListener("keydown", this.onEsc);

              this.resetMap(this.md5);
            } else this.$router.go(-1);
          });
        })
        .catch(err => {
          this.loadFont();
        });
    },
    changePercentage(val) {
      if (val && val.close && val.close == true) {
        this.mapLoading = false;
      } else {
        let vales = Math.ceil((val.current / val.count) * 100);
        if (vales <= 100) {
          this.$set(
            this,
            "percentage",
            Math.ceil((val.current / val.count) * 100)
          );
          this.percentageText = val.text;
        }
      }
      this.$forceUpdate();
    },
    onresize() {
      if (this.pixiMap) {
      }
    },
    resetMap(md5) {
      if (md5) {
        get(this.cadUrl + md5)
          .then(res => {
            if (res.code == 200 && res.data && res.data.mapId) {
              this.mapId = res.data.mapId;
              this.$nextTick(() => {
                this.initData();
              });
              setTimeout(() => {
                this.sendMap(this.mapId);
              }, 500);
            }
          })
          .catch(err => {
            console.log("err", err);
            this.$router.go(-1);
          });
      } else {
        this.initData();
      }
    },

    sendMap() {
      window.loadMapEnd = this.loadMapEnd;

      window.getProjectData = this.getProjectData;
      window.CustomCommandManager = CustomCommandManager.getInstance();
      window.saveProjectData = this.saveProjectData;
      window.getUserSettingData = this.getUserSettingData;
      window.saveUserSettingData = this.saveUserSettingData;
      window.showMouse = this.showMouse;
      window.onGbMouse = this.onGbMouse;
      if (this.mapId) this.loadMap(this.mapId);
    },
    // 加载地图
    loadMap(map_id) {
      this.pixiMap.clearLayers();
      //  map_id="1cef563c";
      this.pixiMap.setMapID(
        map_id,
        window.configData.VUE_APP_BASEURL + "/dwg/",
        getToken(),
        this.productId,
        this.changePercentage
      );
      let reactor = new SprinklerReactor(this.pixiMap);
      this.pixiMap.addReactor(reactor);
      //生成环境不自动保存;
      if (process.env.NODE_ENV !== "development") {
        if (!this.mapTimer) {
          setInterval(() => {
            this.save();
          }, 10 * 60 * 1000);
        }
      }
    },

    loadMapEnd() {
      //加载用户配置信息;
    },
    initData() {
      //rendermode: true 为纯渲染  false 为可编辑
      let isEditCad =
        this.getPackageSimpleByVal("feaCadOnlineEdit") && getToken()
          ? true
          : false;

      const page_canvas = this.$refs.page_canvas;

      this.pixiMap = new CadMap({
        canvas: page_canvas,
        rendermode: !isEditCad
      });
      this.changeMapSize();
      CustomCommandManager.getInstance().initCommand();
      //刚初始化清除啥
      // this.pixiMap.clearLayers();

      page_canvas.addEventListener("contextmenu", function(e) {
        e.preventDefault();
      });
    },

    delete() {
      CommandFactory.getInstance().execCommand("$delete", this.pixiMap);
    },

    // 居中
    fullmap() {
      this.pixiMap.fullMap();
    },
    goBack() {
      this.$router.go(-1);
    },

    //获取用户配置信息;
    getUserSettingData() {
      return new Promise((resolve, reject) => {
        get(this.$setApi("/cus/user/config/"))
          .then(res => {
            let data = {};
            if (res && res.data && typeof res.data == "string") {
              data = JSON.parse(res.data);
              if (typeof data == "string") data = JSON.parse(data);
            }
            resolve(data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    saveUserSettingData(type, data = {}) {
      return new Promise((resolve, reject) => {
        this.getUserSettingData()
          .then(res => {
            let postData = res;
            postData[type] = data;
            post(this.$setApi("/cus/user/config/"), postData).then(res => {
              if (res && res.data) {
                resolve();
              }
            });
          })
          .catch(() => {
            reject();
          });
      });
    }
  },

  destroyed() {
    this.$store.dispatch("user/setStaging", false);
    this.isLoading = false;
    this.pixiMap.clearLayers();
    this.pixiMap = null;
    this.mapId = "";
    if (this.mapTimer) {
      clearInterval(this.mapTimer);
      this.mapTimer = null;
    }
  },
  deactivated() {
    this.$store.dispatch("user/setStaging", false);
    this.isLoading = false;
    if (this.pixiMap !== null) {
      this.pixiMap.clearLayers();
      this.pixiMap = null;
    }

    this.mapId = "";
    if (this.mapTimer) {
      clearInterval(this.mapTimer);
      this.mapTimer = null;
    }
  },

  watch: {}
};
</script>
<style lang="stylus" scoped>
@import '~@/assets/styl/cad'

.home-top-wrapper
  background #52545a !important

.cad-btn-box
  height 46px
  align-items center
  justify-content space-between
  background #42454a

  >>>.el-button--primary
    color #D0D0D1 !important
    background #42454a !important
    border-color #9c9da0 !important

.cad-img
  width 100%

.cad-container
  overflow hidden
  user-select none

  .back-box
    justify-content flex-end
    align-items center

.cad-title
  margin-left 10px
  font-size 16px

.back-svg
  margin-left 28px
  cursor pointer
</style>
